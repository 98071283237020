import React from 'react';
import { Paper } from '@mui/material';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import moment from 'moment';

function FamilyDetails(data) {
  return (
    <Paper style={{ padding: 16, marginTop: 15 }} variant="outlined">
      <div
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 16
        }}
      >
        <Typography
          color="rgba(79, 79, 79, 1)"
          style={{
            fontSize: 18,
            marginBottom: 15
          }}
        >
          Family Details
        </Typography>
        {data
          ? data?.data.map((item, index) => {
              return (
                <>
                  <Paper
                    key={index}
                    style={{
                      textAlign: 'center',
                      padding: 10,
                      marginBottom: 20,
                      borderColor: '#c1e3f1',
                      backgroundColor: '#f2f5fa',
                      position: 'relative'
                    }}
                    variant="outlined"
                  >
                    <Typography fontWeight={500} style={{ fontSize: 18 }}>
                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: '500',
                          wordBreak: 'break-word'
                        }}
                      >
                        {item?.name?.toUpperCase()}
                      </span>
                    </Typography>
                    <Typography
                      color="rgba(79, 79, 79, 1)"
                      variant="subtitle1"
                      style={{
                        textTransform: 'capitalize'
                      }}
                    >
                      Relation : {item?.relation}
                    </Typography>
                    <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
                      {item?.gender} :{' '}
                      {item?.dob
                        ? moment().diff(item?.dob, 'years') + ' Year'
                        : '---'}
                    </Typography>
                    {item?.govIdType ? (
                      <Typography
                        color="rgba(79, 79, 79, 1)"
                        variant="subtitle1"
                      >
                        {item?.govIdType} : {item?.govIdNo}
                      </Typography>
                    ) : null}
                  </Paper>
                </>
              );
            })
          : null}
      </div>
    </Paper>
  );
}

export default FamilyDetails;
