import React from 'react';
import { Typography } from '../../../../../components/Generic/StyledComponent';
import moment from 'moment';
import EditIcon from '@rsuite/icons/Edit';
import { Formik, Field } from 'formik';
import Modal from 'src/components/Modal';
import {
  Paper,
  Button,
  TextField as TextFieldBase,
  FormHelperText,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import LoaderButton from '../loaderButton';
import { USER_ROLES } from 'src/constants';
import { hasRole } from '../../../../MutualAid/claimDetails';
import PlanBenefit from 'src/components/PlanBenefit';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

function ProfileCard({
  data: { name, totalClaimDays, claimDaysLeft, gender, dob },
  productName,
  productId,
  claimDetails: {
    resellerName,
    govId,
    govIdNo,
    mobileNo,
    policyStartDate,
    policyEndDate,
    loanCode,
    endDate,
    startDate,
    claimExistEndDate
  },
  open,
  setOpen,
  handleSubmitClaimDate,
  btnOnPressHandler,
  loading,
  isShowButton
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper
        style={{
          textAlign: 'center',
          padding: 16,
          marginBottom: 20,
          position: 'relative'
        }}
        variant="outlined"
      >
        {' '}
        {hasRole(USER_ROLES.IO_Reviewer) && (
          <Tooltip title=" Click to swap member" placement="right" arrow>
            <div
              style={{
                position: 'absolute',
                right: '10px',
                top: '5px'
              }}
            >
              <Button
                variant="contained"
                endIcon={<SwapHorizIcon style={{ fontSize: '25px' }} />}
                style={{
                  height: '25px',
                  width: '122px',
                  textTransform: 'capitalize',
                  backgroundColor: 'blue',
                  fontSize: '0.6rem',
                  fontWeight: '500'
                }}
                onClick={() => btnOnPressHandler('swap_member')}
              >
                Patient swap
              </Button>
            </div>
          </Tooltip>
        )}
        <div style={{ marginTop: '10px' }}>
          <Typography fontWeight={500} style={{ fontSize: 20 }}>
            <span
              style={{
                wordBreak: 'break-word',
                fontSize: 20,
                fontWeight: '500'
              }}
            >
              {name?.toUpperCase()}{' '}
            </span>

            {hasRole(USER_ROLES.IO_Reviewer_Update) && isShowButton ? (
              <EditIcon
                style={{
                  cursor: 'pointer',
                  color: 'black',
                  marginRight: '10px',
                  fontSize: '20px',
                  marginBottom: '5px'
                }}
                onClick={() => setOpen(!open)}
              />
            ) : (
              ''
            )}
          </Typography>
        </div>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          {gender} : {dob ? moment().diff(dob, 'years') + ' Year' : '---'}
        </Typography>
        {loanCode && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            loan Id : {loanCode ? loanCode : '---'}
          </Typography>
        )}
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          {govId ? govId : '---'} : {govIdNo ? govIdNo : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Mobile No : {mobileNo ? mobileNo : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Policy StartDate :{' '}
          {policyStartDate
            ? moment(policyStartDate).format('DD-MM-YYYY')
            : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Policy EndDate :{' '}
          {policyEndDate ? moment(policyEndDate).format('DD-MM-YYYY') : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          Reseller Name : {resellerName ? resellerName : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          {productName && (
            <PlanBenefit planName={productName} productId={productId} />
          )}
        </Typography>
        {startDate && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            StartDate :{' '}
            {startDate ? moment(startDate).format('DD-MM-YYYY') : '---'}
          </Typography>
        )}
        {claimExistEndDate && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            EndDate :{' '}
            {claimExistEndDate
              ? moment(claimExistEndDate).format('DD-MM-YYYY')
              : '---'}
          </Typography>
        )}
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          IO StartDate :{' '}
          {startDate ? moment(startDate).format('DD-MM-YYYY') : '---'}
        </Typography>
        <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
          IO EndDate : {endDate ? moment(endDate).format('DD-MM-YYYY') : '---'}
        </Typography>
        {endDate && startDate && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            Total Days : {moment(endDate).diff(moment(startDate), 'days')}{' '}
            day(s)
          </Typography>
        )}
        {claimDaysLeft && (
          <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
            Left Reimbursement Days : {claimDaysLeft}
          </Typography>
        )}
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={'Edit Reimbursement Date'}
      >
        <div style={{ margin: 30 }}>
          <Formik
            initialValues={{
              startDate: startDate,
              endDate: endDate,
              claimSlabType: ''
            }}
          >
            {({ values }) => (
              <>
                <Grid container gap={1}>
                  <div style={{ width: '100%' }}>
                    <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
                      Reimbursement Start Date
                    </Typography>
                    <Field
                      type="date"
                      value={values.startDate}
                      name="startDate"
                      component={TextField}
                      minDate={policyStartDate}
                      maxDate={new Date().toISOString().slice(0, 10)}
                    />
                  </div>

                  <div style={{ width: '100%' }}>
                    <Typography color="rgba(79, 79, 79, 1)" variant="subtitle1">
                      Reimbursement End Date
                    </Typography>
                    <Field
                      type="date"
                      value={values.endDate}
                      name="endDate"
                      component={TextField}
                      minDate={values.startDate}
                      maxDate={
                        policyEndDate
                          ? new Date(moment(policyEndDate).add(3, 'M'))
                              .toISOString()
                              .slice(0, 10)
                          : new Date().toISOString().slice(0, 10)
                      }
                      disabled={!values.startDate}
                    />
                  </div>

                  <Field
                    value={values.claimSlabType}
                    name="claimSlabType"
                    component={SelectField}
                  />

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      width: '100%'
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        marginRight: 10,
                        textTransform: 'capitalize'
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <LoaderButton
                      loading={loading}
                      variant="contained"
                      style={{
                        textTransform: 'capitalize'
                      }}
                      onClick={() => handleSubmitClaimDate(values)}
                      disabled={
                        !values.claimSlabType ||
                        !values.endDate ||
                        !values.startDate
                      }
                    >
                      Update
                    </LoaderButton>
                  </div>
                </Grid>
              </>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default ProfileCard;

function TextField({
  field: { onChange, value, name },
  form: { errors },
  label,
  type = 'text',
  minDate,
  maxDate,
  disabled
}) {
  return (
    <>
      <TextFieldBase
        name={name}
        label={label}
        variant="outlined"
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
        type={type}
        error={!!errors[name]}
        inputProps={{
          min: minDate,
          max: maxDate,
          format: 'DD/MM/YYYY'
        }}
        disabled={disabled}
      />
      <FormHelperText
        style={{ marginBottom: '10px', marginTop: '-10px' }}
        error
      >
        {errors[name]}
      </FormHelperText>
    </>
  );
}

function SelectField({ field: { onChange, value, name }, form: { errors } }) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel sx={{ marginTop: 1 }} id="demo-simple-select-label">
          Claim Slab Type
        </InputLabel>
        <Select
          name={name}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Claim Slab Type"
          onChange={onChange}
        >
          <MenuItem value={'OPCASH'}>NORMAL</MenuItem>
          <MenuItem value={'ICU'}>ICU</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
