import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useCredentials from 'src/hooks/useCredentials';
import { MutualAid } from 'src/services/api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function AddModal({ open, handleClose, handleSuccess }) {
  const { accessToken } = useCredentials();
  const [details, setDetails] = React.useState({
    label: '',
    value: ''
  });
  const [loading, setLoading] = React.useState(false);

  async function saveData() {
    setLoading(true);
    try {
      const data = { category: details?.label, days: details?.value };
      const response = await MutualAid.saveDiagnosticsData({
        accessToken,
        data
      });
      if (response?.code === 202) {
        handleSuccess();
        setLoading(false);
      } else {
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={!loading && handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add diagnostics category
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={!loading && handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              flexDirection: 'column',
              width: '400px'
            }}
          >
            <TextField
              id="outlined-basic"
              label="Category name"
              variant="outlined"
              style={{ width: '100%' }}
              value={details.label}
              onChange={(e) => {
                let value = e.target.value;

                if (/^[a-zA-Z0-9]*$/.test(value) && value?.length < 20) {
                  setDetails({ ...details, label: value });
                }
              }}
            />
            <TextField
              id="outlined-basic"
              label="Category days"
              variant="outlined"
              value={details.value}
              onChange={(e) => {
                let value = e.target.value;
                if (
                  /^\d*$/.test(value) &&
                  (value === '' || (Number(value) >= 1 && Number(value) <= 100))
                ) {
                  setDetails({ ...details, value: value });
                }
              }}
              style={{ width: '100%' }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={saveData}
            variant="contained"
            style={{ background: '#Db2228', color: 'white' }}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
