import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React from 'react';
import { hasRole } from '../..';
import { USER_ROLES } from 'src/constants';

const SelectLable = ({
  disabled,
  diagnosticsList,
  name,
  onChange,
  error,
  value,
  labelColor = 'black'
}) => {
  return (
    <div>
      <Typography
        color="rgba(79, 79, 79, 1)"
        variant="subtitle1"
        style={{ color: labelColor }}
      >
        Diagnostics category
      </Typography>
      <FormControl fullWidth>
        <InputLabel
          sx={{ marginTop: 1, width: '100%' }}
          id="demo-simple-select-label"
        >
          Category
        </InputLabel>
        <Select
          name={name}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Category"
          onChange={onChange}
          disabled={
            !(
              hasRole(USER_ROLES.IO_Reviewer) ||
              hasRole(USER_ROLES.DOC_Reviewer)
            ) || disabled
          }
        >
          {diagnosticsList?.map((item, index) => {
            return (
              <MenuItem
                value={item.id}
                key={item.id}
                style={{ width: '500px' }}
              >
                {item?.category} ({item?.days})
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText
          style={{ marginBottom: '10px', marginTop: '-10px' }}
          error
        >
          {error}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default SelectLable;
