import axios from 'axios';
import * as API_URL from '../constants';
import { toast } from 'react-toastify';
import { enrollmentHeader, enrollmentHeaderForPdf, headers } from 'src/utils';
import {
  DeleteApi,
  Fetch,
  GetApi,
  PostApi,
  PutApi
} from 'src/services/middleware';
import { claim_x_reseller_client_Id } from 'src/config/Headers';

export let client_access_key =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_KEY_${process.env.REACT_APP_MODE}`
  ];
export let client_access_secret =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_SECRET_${process.env.REACT_APP_MODE}`
  ];

const claimsHeaders = () => {
  return {
    headers: {
      ...headers().headers,
      'client-access-key': client_access_key,
      'client-access-secret': client_access_secret
    }
  };
};

///   generate prescription pdf
export const generatePrescriptionPdf = async (appointmentId, patientId) => {
  try {
    const res = await GetApi(
      API_URL.generatePrescriptionPdf(appointmentId, patientId)
    );
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch  vitals list
export const fetchVitalListAPI = async () => {
  try {
    const res = await GetApi(API_URL.fetchVitalListURL, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch parameters track e.g symptoms and vitals
export const fetchParameterTrackAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      API_URL.parametersTrackFetchURL(patient, appointment),
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// create parameters track e.g symptoms and vitals
export const createParameterTrackAPI = async (data) => {
  try {
    const res = await PostApi(API_URL.createParametersTrack, data, headers());
    if (res.status == 200) {
      toast.success('Parameter to track added successfully');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// initiate exotel call api
export const initiateCallAPI = async (patient, id) => {
  try {
    const res = await PostApi(API_URL.exotelCall(patient, id), {}, headers());
    if (res.status == 200) {
      console.log('call initiate success');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// upload generated prescrition report
export const uploadGeneratedPdfAPI = async (data) => {
  try {
    const res = await PostApi(API_URL.uploadGeneratedPdfURL(), data, headers());
    if (res.status == 200) {
      toast.success('Pdf upload successfully');
    } else {
      toast.error(
        'Issue encountered while sending the prescription. Please contact care for assistance.'
      );
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// counsellor fetch api
export const fetchPatientTrackerRecordAPI = async (appointment, patient) => {
  try {
    const res = await GetApi(
      API_URL.patientTrackerURL(patient, appointment),
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
    if (res.status == 400) {
    }
  } catch (err) {
    toast.error('something went wrong');
    console.log(err);
  }
};

export const GetPatientAppointment = async (patient_id, page, date) => {
  try {
    const res = await GetApi(
      API_URL.patientAppointmentUrl(patient_id, page, date),
      headers()
    );
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// get patient diet
export const GetPatientDiet = async (patient_id, start, end) => {
  try {
    const res = await GetApi(
      API_URL.patientDietUrl(patient_id, start, end),
      headers()
    );
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// get patient exercise
export const GetPatientExercise = async (patient_id, start, end) => {
  try {
    const res = await GetApi(
      API_URL.patientExerciseUrl(patient_id, start, end),
      headers()
    );
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// get patient medication
export const GetPatientMedication = async (patient_id, start, end) => {
  try {
    const res = await GetApi(
      API_URL.patientMedicationUrl(patient_id, start, end),
      headers()
    );
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch all symptom list api
export const fetchAllSymptomAPI = async () => {
  try {
    const res = await GetApi(API_URL.fetchAllSymptomURL, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// previous report fetch api
export const createPatientReportAPI = async (data, patient_id) => {
  try {
    const res = await PostApi(
      API_URL.patientPreviousReportURL(patient_id),
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Patient report created');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// previous report fetch api
export const fetchReportListAPI = async (patient) => {
  try {
    const res = await GetApi(
      API_URL.patientPreviousReportURL(patient),
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// previous report fetch api
export const fetchReportListWithPaginationAPI = async (
  page,
  patient,
  report_type
) => {
  try {
    const res = await GetApi(
      API_URL.patientPreviousReportPaginURL(patient, page, report_type),
      headers()
    );
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// previous report fetch api
export const fetchPreviousPriscriptionAPI = async (page, patient) => {
  try {
    const res = await GetApi(
      API_URL.previousPrescriptionURL(patient, page),
      headers()
    );
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// previous report fetch api
export const fetchDownloardPrescription = async (user, patient) => {
  try {
    let MyHeaders = new Headers();
    MyHeaders.append('Accept', `application/json`);
    MyHeaders.append('Content-Type', `application/pdf`);
    MyHeaders.append('cache-control', `no-cache`);
    MyHeaders.append(
      'X-RESELLER-Client-Id',
      `b4c83e9a-e5f7-4aea-a03d-3ef209f002ac`
    );
    MyHeaders.append('X-RESELLER-Client-Secret', `no-cache`);
    let RequestOptions = {
      method: 'GET',
      headers: MyHeaders
    };
    return await Fetch(
      API_URL.downloardPrescriptionURL(user, patient),
      RequestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        return blob;
      });
  } catch (err) {
    toast.error('something went wrong');
  }
};

// counsellor fetch api
export const addAppointmentFollowUpAPI = async (data) => {
  try {
    const res = await PostApi(
      API_URL.appointmentFollowUpURL(data.patient, data.appointment),
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Add follow up appointment');
    }
  } catch (err) {
    if (err.response.status === 400) {
      toast.error(err.response.data.data);
    } else {
      toast.error('something went wrong');
    }
  }
};

// counsellor fetch api
export const fetchAppointmentFollowUpAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      API_URL.appointmentFollowUpURL(patient, appointment),
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// counsellor fetch api
export const fetchCounsellorAPI = async (appointment, patient) => {
  try {
    const res = await GetApi(
      API_URL.counsellorNotesURL(appointment, patient),
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// counsellor update api
export const updateCounsellorAPI = async (data) => {
  try {
    const res = await PostApi(API_URL.counsellorNotesURL(), data, headers());
    if (res.status == 200) {
      toast.success('Prescription has been saved');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// delete diagnosis image api
export const deleteDiagnosisAPI = async (data) => {
  try {
    const res = await PostApi(API_URL.deleteDiagnosisImageURL, data, headers());
    if (res.status == 200) {
      toast.success('Image successfull delete');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
// hand written prescription upload api
export const fetchHandPrescriptionAPI = async (appointment, patient) => {
  try {
    const res = await GetApi(
      API_URL.uploadPrescriptionURL(appointment, patient),
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// hand written prescription upload api
export const prescriptionUploadAPI = async (data) => {
  try {
    const res = await PostApi(API_URL.uploadPrescriptionURL(), data, headers());
    if (res.status == 200) {
      toast.success('Prescription has been saved');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// delete prescription api
export const deletePrescriptionAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.deletePrescriptionURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Prescription has been deleted');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch consultation api
export const fetchConsultationAPI = async (appointment, patient) => {
  try {
    const res = await GetApi(
      `${API_URL.fetchPatientConsultationURL(appointment, patient)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch consultation api
export const fetchNewConsultationAPI = async (appointment, patient) => {
  try {
    const res = await GetApi(
      `${API_URL.fetchPatientNewConsultationURL(appointment, patient)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// udpate consultation api
export const updateNewConsultationAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.fetchPatientNewConsultationURL(
        data.appointment,
        data.patient
      )}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('chief complaints added');
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const updateConsultationAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.updateSymptomVitalURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Vitals has been updated.');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const getWeightManagementAPI = async (appointmentId, patientId) => {
  try {
    const res = await GetApi(
      `${API_URL.weightManagementURL}?appointment=${appointmentId}&patient=${patientId}`,
      headers()
    );
    if (res.status === 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const addWeightManagementAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.weightManagementURL}`,
      data,
      headers()
    );
    if (res.status === 200 && res?.data?.message) {
      toast.success(res?.data?.message);
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch disease list
export const fetchDiseaseListAPI = async () => {
  try {
    const res = await GetApi(`${API_URL.fetchDiseaseURL}`, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch symptom list
export const fetchSymptomListAPI = async (disease_id) => {
  try {
    const res = await GetApi(
      `${API_URL.fetchSymptomListURL(disease_id)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch symptom option list
export const fetchSymptomOptionListAPI = async (symptom_id) => {
  try {
    const res = await GetApi(
      `${API_URL.fetchSymptomOptionListURL(symptom_id)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// publish prescription api
export const publishPrescriptionAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.publishPrescriptionURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Prescription has been published.');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// update patient medical history
export const updatePatientMedicalHistoryAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.medicalHistoryUpdateURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Updated Medical History.');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient medical history.
export const fetchPatientMedicalHistoryAPI = async (patient) => {
  try {
    const res = await GetApi(
      `${API_URL.medicalHistoryFetchURL(patient)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// update patient social history
export const updatePatientSocialHistoryAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.socialHistoryUpdateURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Updated Social History.');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient social history
export const fetchPatientSocialHistoryAPI = async (patient) => {
  try {
    const res = await GetApi(
      `${API_URL.socialHistoryFetchURL(patient)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// update patient family history
export const updatePatientFamilyHistoryAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.familyHistoryUpdateURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Updated Family History.');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient family history
export const fetchPatientFamilyHistoryAPI = async (patient) => {
  try {
    const res = await GetApi(
      `${API_URL.familyHistoryFetchURL(patient)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// update patient diagnosis
export const updatePatientDiagnosisAPI = async (data) => {
  try {
    const res = await PostApi(`${API_URL.diagnosisUpdateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Diagnosis updated');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient diagnosis
export const fetchPatientDiagnosisAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.diagnosisFetchURL(patient, appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// create patient medicine
export const createPatientMedicationAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.medicationCreateURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Medication details created');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
export const updatePatientMedicationAPI = async (data) => {
  try {
    const res = await PutApi(`${API_URL.medicationCreateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Medication details updated');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
export const deletePatientMedicationAPI = async (data) => {
  const token = localStorage.getItem('token');
  try {
    const res = DeleteApi(`${API_URL.medicationDeleteURL}`, {
      ...headers(),
      data
    });

    if (res.status == 200) {
      toast.success('Medication details deleted');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient medicine
export const fetchPatientMedicationAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.medicationFetchURL(patient, appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const fetchPatientMedicationGroupAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(`${API_URL.medicationGroupFetchURL()}`, headers());
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// create patient exercise
export const createPatientExerciseAPI = async (data) => {
  try {
    const res = await PostApi(`${API_URL.exerciseCreateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Exercise details created');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// update patient exercise
export const updatePatientExerciseAPI = async (data) => {
  try {
    const res = await PutApi(`${API_URL.exerciseCreateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Exercise details updated');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient exercise
export const fetchPatientExerciseAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.exerciseFetchURL(patient, appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// create patient diet
export const createPatientDietAPI = async (data) => {
  try {
    const res = await PostApi(`${API_URL.dietCreateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Diet details created');
      return res;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// edit patient diet
export const editPatientDietAPI = async (data) => {
  try {
    const res = await PutApi(`${API_URL.dietCreateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Diet details updated');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient diet
export const fetchPatientDietAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.dietFetchURL(patient, appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};
export const deletePatientDietAPI = async (data) => {
  try {
    const res = DeleteApi(`${API_URL.deleteCreateURL}`, {
      ...headers(),
      data
    });
    if (res.status == 200) {
      toast.success('diet deleted successfully');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// create patient wound care
export const createPatientWoundCareAPI = async (data) => {
  try {
    const res = await PostApi(`${API_URL.woundCareCreateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Wound care details created');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient wound care
export const fetchPatientWoundCareAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.woundCareFetchURL(patient, appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// create patient lab test
export const createPatientLabTestAPI = async (data) => {
  try {
    const res = await PostApi(`${API_URL.labTestCreateURL}`, data, headers());
    if (res.status == 200) {
      toast.success('Lab Test details created');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient lab tests
export const fetchPatientLabTestAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.labTestFetchURL(patient, appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// udpate patient other advice
export const updateOtherAdviceAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.otherAdviceUpdateURL}`,
      data,
      headers()
    );
    if (res.status == 200) {
      toast.success('Other advice updated');
      return res?.data?.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch patient other advice
export const fetchOtherAdviceAPI = async (patient, appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.otherAdviceFetchURL(patient, appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// Get patient filled symptoms
export const GetPatientFilledSymptoms = async () => {
  try {
    const res = await GetApi(`${API_URL.filledSymptomsUrl}`, headers());
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// Get patient filled vitals
export const GetPatientFilledVitals = async () => {
  try {
    const res = await GetApi(`${API_URL.filledVitalsUrl}`, headers());
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// For pagination symptoms api
export const GetPatientFilledSymptomsPagination = async (page) => {
  // alert(url)
  try {
    const res = await GetApi(
      `${API_URL.filledSymptomsUrl}?page=${page}`,
      headers()
    );
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// For pagination vitals api
export const GetPatientFilledVitalsPagination = async (page) => {
  try {
    const res = await GetApi(
      `${API_URL.filledVitalsUrl}?page=${page}`,
      headers()
    );
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// For pagination vitals api for doctor
export const GetPatientFilledVitalsDoctor = async (
  page,
  patient_id,
  vital_id
) => {
  try {
    const res = await GetApi(
      `${API_URL.filledVitalsDoctorUrl}?patient_id=${patient_id}&page=${page}${
        vital_id ? `&vital_id=${vital_id}` : ''
      }`,
      headers()
    );
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const GetPatientFilledVitalsListDoctor = async () => {
  try {
    const res = await GetApi(`${API_URL.filledVitalsListDoctorUrl}`, headers());
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// For pagination symptoms api for doctor
export const GetPatientFilledSymptomsDoctor = async (page, patient_id) => {
  try {
    const res = await GetApi(
      `${API_URL.filledSymptomsDoctorUrl}?page=${page}&patient_id=${patient_id}`,
      headers()
    );
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// For patient Diagnosis report
export const GetPatientDiagnosisReport = async (page, patient_id) => {
  try {
    const res = await GetApi(
      `${API_URL.patientDiagnosisUrl}?patient=${patient_id}&page=${page}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// // For patient Diagnosis report
// export const GetPatientDiagnosisReport = async (page, patient_id) => {
//   try {
//     const res = await GetApi(
//       `${API_URL.patientDiagnosisUrl}?page=${page}&patient=${patient_id}`,
//       headers()
//     );
//     if (res.status == 200) {
//       return res.data.data
//     }
//   } catch (err) {
//     toast.error('something went wrong');
//   }
// };

// List of all vitals
export const GetVitalsList = async () => {
  try {
    const res = await GetApi(API_URL.vitalListUrl, headers());
    if (res.status == 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// medicine search api
export const searchMedicineApi = async (search, appointment_id) => {
  try {
    const res = await GetApi(
      API_URL.medicineSearchUrl(search, appointment_id),
      headers()
    );
    if (res.status == 200) {
      return res?.data?.results;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const fetchHraReportsDetails = async (data) => {
  try {
    const res = await PostApi(
      API_URL.getHraReportsDetailsURL(),
      data,
      headers()
    );
    if (res.status == 200) {
      return res.data;
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    toast.error('something went wrong');
    console.log(err);
  }
};

export const HraReportAction = async (hraId, action) => {
  try {
    let RequestOptions = {
      method: 'GET',
      ...enrollmentHeaderForPdf()
    };
    return await Fetch(API_URL.getHraReportURL(hraId, action), RequestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        return blob;
      });
  } catch (err) {
    toast.error(err.message);
  }
};

//Assign Diet Preference
export const fetchDietPreferenceOptions = async () => {
  try {
    const res = await GetApi(`${API_URL.getDietPreferenceOption()}`, headers());

    if (res.status == 200) {
      return res?.data;
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const fetchDietPreferencePdf = async (
  preference,
  calories,
  cuisines,
  appointmentId
) => {
  try {
    const token = localStorage.getItem('token');
    return await Fetch(
      API_URL.getDietPreferencePdfUrl(preference, calories, cuisines),
      {
        ...enrollmentHeaderForPdf()
      }
    )
      .then((res) => {
        if (res.status === 400) {
          toast.info(
            'The provided combination does not match our records. Please change your combination and try again.'
          );
          return;
        }

        return res.blob();
      })
      .then((blob) => {
        return blob;
      });
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const saveDietPreviewDetails = async (data) => {
  try {
    const res = await PostApi(API_URL.saveDietPreferenceUrl(), data, headers());

    if (res.status == 200) {
      return res.data;
    } else {
      toast.error(res.message);
    }
  } catch (err) {
    toast.error('something went wrong');
    console.log(err);
  }
};

export const getDietPreviewDetails = async (appointment_id) => {
  try {
    const res = await GetApi(
      API_URL.getDietPreferenceData(appointment_id),
      headers()
    );

    if (res.status == 200) {
      return res.data.responseObject;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

//QUERY RAISED

export const fetchQueryRaisedAPI = async (appointment) => {
  try {
    const res = await GetApi(
      `${API_URL.fetchQueryRaisedURL(appointment)}`,
      headers()
    );
    if (res.status == 200) {
      return res.data.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const saveNewQueryRaiseAPI = async (data) => {
  try {
    const res = await PostApi(
      `${API_URL.saveQueryRaiseUrl()}`,
      data,
      headers()
    );
    if (res.status == 200) {
      return res;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const fetchLabTestReport = async (query, pdfName) => {
  try {
    return await Fetch(`${API_URL.getLabTestReportUrl(query)}`, claimsHeaders())
      .then((res) => res.blob())
      .then((blob) => {
        if (!blob) {
          return null;
        }
        return blob;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        return err;
      });
  } catch (err) {
    toast.error('something went wrong');
  }
};

export const getPatnerLabReport = async (data) => {
  try {
    const res = await PostApi(
      API_URL.getPatnerLabReportURL(),
      data,
      claimsHeaders()
    );
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    if (err.response.status === 400) {
      toast.error(err.response.data.data);
    } else {
      toast.error('something went wrong');
    }
  }
};

export const fetchAppointmentStatus = async (appointmentId) => {
  const res = await GetApi(
    `${API_URL.fetchAppointmentStatusURL(appointmentId)}`,
    headers()
  );
  if (res.status == 200) {
    return res.data?.data;
  }
};
export const fetchDownloadMultipleLanguagePrescription = async (
  user,
  patient,
  languageCode
) => {
  let MyHeaders = new Headers();
  MyHeaders.append('Accept', `application/json`);
  MyHeaders.append('Content-Type', `application/pdf`);
  MyHeaders.append('cache-control', `no-cache`);
  MyHeaders.append('X-RESELLER-Client-Id', claim_x_reseller_client_Id);
  MyHeaders.append('X-RESELLER-Client-Secret', `no-cache`);

  let RequestOptions = {
    method: 'GET',
    ...enrollmentHeader()
  };

  return await Fetch(
    API_URL.downloadPrescriptionMultipleLangURL(user, patient, languageCode)
  )
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    });
};
